import router from './router'
import store from './store'
// import { Toast } from 'vant';
// import { Message } from 'element-ui'
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import { resetRouter } from './router/index.js'
import { refreshDeviceToken } from "./api/login"
import { readMessage } from "./api/message";
import { deepClone, isSystem } from '@/utils/index'
import { Toast } from 'vant';

// NProgress.configure({ showSpinner: false })

const whiteList = ['/login', "/privacy", "/404","/register"] //白名单
const topMeun = ['/login', "/", "/index", "/menu", "/messageList", "/personal", "/demo"]; //顶级菜单
const _system = isSystem();
router.beforeEach((to, from, next) => {
    // let parameterStyle = localStorage.getItem("parameterStyle");
    // if (parameterStyle == 'params') {
    //     localStorage.removeItem("parameterStyle")

    //     let newTo = deepClone(to);
    //     let _params = JSON.parse(localStorage.getItem("params")),
    //         _name = localStorage.getItem("pathName")
    //     newTo.params = _params
    //     newTo.name = _name
    //         // console.log(_params);
    //     console.log("newTo", newTo);
    //     // console.log(newTo);
    //     next({
    //         name: _name,
    //         params: _params
    //     })
    //     localStorage.removeItem("pathName")
    //     localStorage.removeItem("params")
    //     return;
    // }

    if (to.path === '/menuChild') {
        to.meta.title = store.getters.routerTitle
    }
    store.dispatch("setHistory", {
        name: to.name,
        noCache: to.meta.noCache
    })
    /**
     * 未登录 
     *      路径是否在白名单中： 在： 打开新页面；不在 进入登录页
     * 登录：
     *  判断当前用户是否获取基础数据 
     *      没有 获取数据 进入页面
     *      有 
     *          判断进入页面是否存在与菜单； 没有 打开新页面；有进入页面      
     * 
     * 
     */
    // let _openWindowData = {
    //     canGoBack: true,
    //     url: to.fullPath,
    //     query: to.query,
    //     params: to.params,
    //     pathName: to.name,
    // }
    // if (to.path == "/" || to.path == "/index" || to.path == "/login") {
    //     _openWindowData.canGoBack = false
    // }
    let _token = getToken();
    if (!_token) {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            next(`/login`) // 否则全部重定向到登录页
        }
        return;
    }
    if (to.path === '/login') {
        next({ path: '/' })
        return;
    }
    if (store.getters.roles.length === 0) {
        store.dispatch('GetInfo').then(res => {
            if (!res.deviceToken) {

                if (_system != 'window') {
                    interaction.bridge({
                        msgType: "setPushAlias",
                        data: {
                            isAdd: true,
                            alias: res.user.userId,
                            aliasType: "middleground-userid"
                        },
                    }).then(res => {
                        console.log(res, "setPushAlias Token");

                        if (res) {
                            refreshDeviceToken({
                                deviceToken: res,
                                platform: _system == "ios" ? 2 : 1
                            })
                        }
                        // else {
                        //     Toast.fail("请打开设备权限用于接收消息提醒")
                        // }
                    });
                }
            }
            store.dispatch('GenerateRoutes', {}).then(accessRoutes => {
                resetRouter();
                router.addRoutes(accessRoutes) // 动态添加可访问路由表
                // router.addRoutes(accessRoutes) // 动态添加可访问路由表
                next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
            })
        })
            .catch(err => {
                store.dispatch('FedLogOut').then(() => {
                    // this.$message.error(err)
                    console.log(err);
                    // Toast.fail(err)
                    next({ path: '/' })
                })
            })


        store.dispatch('GetMessageModuleList');
    } else {
        next();
        // localStorage.setItem("store", JSON.stringify(store.state));
        // let _nowPath = window.location.pathname;
        // if (!topMeun.includes(to.path) && to.path != _nowPath && _system != 'window') {
        //     // console.log(to);
        //     // openNewWindow(_openWindowData)
        //     next();
        // } else {
        //     next();
        // }
    }
    let _detailsPage = store.getters.messageModuleList.filter(item => {
        return item.href == to.path
    })
    // 安环隐患单独处理下，因为消息跳转地址为列表页，所以不对当前类型消息做全部已读处理
    if (to.path == '/hazardInvestigation' && to.query && !to.query.id) return;
    if (_detailsPage[0]) {
        readMessage({
            router: to.path,
            parameter: to.query,
        })
    }



})


// router.afterEach((to, from) => {
//     console.log(to, "**********************");
//     if (localStorage.getItem("params")) {
//         to.params = JSON.parse(localStorage.getItem("params"))
//         console.log(to);
//     }

// })


// const openNewWindow = ({ canGoBack, url, params, pathName }) => {



//     let _url = window.location.protocol + "//" + window.location.host + url;
//     let _style = Object.keys(params).length > 0 ? "params" : "query"
//     localStorage.setItem("parameterStyle", _style)
//     if (_style == "params") {
//         localStorage.setItem("pathName", pathName)
//         localStorage.setItem("params", JSON.stringify(params))
//     }

//     interaction.bridge({
//         msgType: "openNewWindow",
//         data: {
//             canGoBack,
//             url: _url,
//             // url: "http://192.168.0.11:8080/index",
//             titleVisible: false
//         }
//     })

// }


window.windowShow = () => {
    console.log("进入页面展示");
}