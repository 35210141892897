import Vue from 'vue'
import Vuex from 'vuex'
// import app from './modules/app'
import user from './modules/user'
// import tagsView from './modules/tagsView'
import permission from './modules/permission'
import meun from './modules/meun'
import include from './modules/include'
// import cacheMeun from './modules/cacheMeun'
// import settings from './modules/settings'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
    // state: {
    //     count: 0
    // },
    modules: {
        // app,
        user,
        // tagsView,
        meun,
        permission,
        include,
        // cacheMeun
        // settings
    },
    getters
})

export default store