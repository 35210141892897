import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import { getToken } from '@/utils/auth'
import { Dialog } from 'vant';
import { Toast } from 'vant';
Vue.use(VueRouter)

export const constantRoutes = [{
    path: '/',
    redirect: "/index", //重定向
    component: Layout,
    // hidden: true,
    // component: () =>
    //     import ('../views/todo/list.vue')
    children: [{
        path: "/personal",
        name: "personal",
        component: () =>
            import("@/views/BasicModule/personalCenter/index.vue"),
        meta: {
            title: "个人中心",
            noCache: true
        }
    },
    {
        path: "/editpassword",
        name: "editpassword",
        component: () =>
            import("@/views/BasicModule/personalCenter/editpassword.vue"),
        meta: {
            title: "修改密码",
            noCache: true
        }
    },
    /**
     * 以下页面为工厂APP页面最新菜单
     *   
     */
    /* 基础模块 */
    {
        path: "/index",
        name: "home",
        component: () =>
            import("@/views/BasicModule/home.vue"),
        meta: {
            title: "首页",
            noCache: true,
        }
    },
    { //菜单
        path: "/menu",
        name: "menuPage",
        component: () =>
            import("@/views/BasicModule/menuPage.vue"),
        meta: {
            title: "智蕴植保",
            noCache: true,
        }
    },
    { //子级菜单
        path: "/menuChild",
        name: "menuChildPage",
        component: () =>
            import("@/views/BasicModule/menuChildPage.vue"),
        meta: {
            title: "仓储管理系统",
            noCache: true
        }
    },
    {
        path: "/messageList",
        name: "messageList",
        component: () =>
            import("@/views/BasicModule/message/list.vue"),
        meta: {
            title: "消息列表",
            noCache: true,
            rightIcon: [{
                buttonType: "2",
                Tips: "全部已读",
                iconEvent: "allRead",
            }]
        }
    },
    {
        path: "/messageDetails",
        name: "messageDetails",
        component: () =>
            import("@/views/BasicModule/message/details.vue"),
        meta: {
            title: "消息详情",
            noCache: true
        }
    },
    {
        name: "demo",
        path: "/demo",
        component: () =>
            import("@/views/demo/demo1.vue"),
        meta: {
            title: "原生交互",
            noCache: true
        },
    },
    ]
},
{
    path: '/login',
    name: "login",
    component: (resolve) => require(['@/views/BasicModule/login'], resolve),
    hidden: true,
    meta: {
        title: "登录",
        noCache: true
    },
},
{
    path: '/register',
    name: "register",
    component: (resolve) => require(['@/views/BasicModule/register'], resolve),
    hidden: true,
    meta: {
        title: "注册",
        noCache: true
    },
},
{
    path: '/privacy',
    name: "privacy",
    component: (resolve) => require(['@/views/BasicModule/privacy'], resolve),
    hidden: true,
    meta: {
        title: "《《工厂隐私权政策》》",
        noCache: true
    },
},
    /* {
        path: '/404',
        name: '404',
        component: (resolve) => require(['@/views/BasicModule/404'], resolve),
        hidden: true,
        meta: {
         title: "404页面",
         noCache: true
        },
    },
    {
      path: '*',    // 此处需特别注意至于最底部
      redirect: '/404'
    } */
]


const createRouter = () => new VueRouter({
    mode: 'history', // 去掉url中的#
    routes: constantRoutes,
    scrollBehavior(to, from, savePosition) {
        if (savePosition) {
            return savePosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

const router = createRouter()
router.beforeEach((to, from, next) => {
    if (from.fullPath == "/viewTask" || from.name == "InspectionRecords") {
        if (window.hasSubmittedForm) {
            // 如果表单已提交，则不进行确认，直接导航
            window.hasSubmittedForm = false; // 重置标志
            next();
        } else {
            Dialog.confirm({
                message: '您确定离开吗？',
                confirmButtonText: '离开',
                confirmButtonColor: '#26bfb2',
                cancelButtonColor: '#26bfb2',
                closeOnPopstate: false,
            }).then(() => {
                // on confirm
                next();
            }).catch(() => {
                next(false);
                // on cancel
            });
        }
    }
    else if (from.fullPath == '/realInventory') {
        const goBack = localStorage.getItem('goBack');
        if (goBack) {
            Toast.fail('请提交本次盘点操作后再返回');
        } else {
            next();
        }
    }
    else {
        next();
    }
})

export default router

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // the relevant part
}