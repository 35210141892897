import request from '@/utils/request'

const url = process.env.VUE_APP_GONGCHANG_URL
// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
    return request({
      url: url+ '/system/dict/data/type/' + dictType,
      method: 'get'
    })
  }

//获取供应商列表
export function getSupplierList(params) {
    return request({
        url:url + '/supplier/getSupplierList',
        method: 'get',
        params
    })
}

// 查询采购、请购人员信息
export function selectUserByRoleKeys(data) {
    return request({
        url:url + '/system/user/query/selectUserByRoleKeys',
        method: 'post',
        data: data
    })
}

// 查询部门列表
export function listDept(query) {
    return request({
      url:url + '/system/dept/list',
      method: 'get',
      params: query
    })
  }

// 查询用户个人信息
export function getUserProfile() {
  return request({
      url: url + '/system/user/profile',
      method: 'get'
  })
}
