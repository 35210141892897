import request from '@/utils/request'

// 查询消息模块配置列表
export function getMessageConfList(query) {
    return request({
        url: '/message/module/list',
        method: 'get',
        params: query
    })
}
// 查询消息模块配置列表

// 查询消息列表
export function getMessageList(query) {
    return request({
        url: '/message/data/list',
        method: 'get',
        params: query
    })
}
// 消息已读
export function readMessage(data) {
    return request({
        url: `/message/data/readMessage`,
        method: 'post',
        data
    })
}
// 全部消息已读
export function readAllMessage(data) {
    return request({
        url: `/message/data/readAllMessage`,
        method: 'post',
        data
    })
}
// 菜单消息数量查询
export function getMenuMessage(id) {
    return request({
        url: `/message/data/getMenuMessage/${id}`,
        method: 'get',
    })
}