import { constantRoutes } from '@/router'
import { getRouters } from '@/api/menu'
import Layout from '@/layout/index'
import store from '../index'

const permission = {
    state: {
        routes: [],
        addRoutes: []
    },
    mutations: {
        // SET_ROLES
        // rolse
        //  routes
        SET_ROUTES: (state, routes) => {
            state.addRoutes = routes
            state.routes = constantRoutes.concat(routes)
        }
    },
    actions: {
        // 生成路由
        GenerateRoutes({ commit }) {
            return new Promise(resolve => {
                // 向后端请求路由数据
                getRouters().then(res => {
                    // console.log(res);
                    res.data.forEach(item => {
                        item.level = 1
                            // let _bool = item.name.includes("/");
                            // if (_bool) {
                            //     item.name.splice(0, 1)
                            // }
                    });

                    const accessedRoutes = filterAsyncRouter(res.data)
                        // console.log(accessedRoutes, "accessedRoutes");
                        // accessedRoutes.push({ path: '*', redirect: '/404', hidden: true })
                    commit('SET_ROUTES', accessedRoutes)
                    resolve(accessedRoutes)
                })
            })
        },
        infoRoutes({ commit }) {
            commit("SET_ROUTES", [])
        }
    }
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, parent) {
    return asyncRouterMap.filter(route => {

        let _ind = route.name && route.name.indexOf("/");
        if (_ind > -1) {
            route.name = route.name.slice(1);
        }
        if (route.component) {
            // Layout组件特殊处理
            if (route.component === 'Layout') {
                if (route.level) {
                    route.component = Layout
                } else {
                    route.component = {
                        render: c => c("router-view")
                    }
                }
            } else {
                route.component = loadView(route.component)
            }
        }
        if (route.meta.rightIcon) {
            // let _bool = false
            route.meta.rightIcon = route.meta.rightIcon.map(iconData => {
                return {
                    buttonType: iconData.buttonType,
                    icon: iconData.meta.icon,
                    Tips: iconData.meta.title,
                    iconEvent: iconData.buttonEvent,
                }
            })
        }

        if (route.children != null && route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children, route)
        }
        return true
    })
}

export const loadView = (view) => { // 路由懒加载
    return (resolve) => require([`@/views/${view}`], resolve)
}

export default permission