const meun = {
    // namespaced: true,
    state: {
        meunTitle: "",
        menuList: [],
        menuParentId: "",
        // navbarRightButton: [],
        // mateList: ["PurchaseApproval"],
        rightBtnDefault: {
            PurchaseApproval: "apps-o",
            ContractApproval: "apps-o",
            OrderApproval: "apps-o",
            PaymentApproval: "apps-o",
            PaymentBatchApproval: "apps-o",
            productionAndProcessingList: "apps-o",
        },

    },

    mutations: {
        SET_MENULIST: (state, menuList) => {
            state.menuList = menuList;
        },
        SET_MEUNTITLE: (state, title) => {
            state.meunTitle = title
        },
        SET_MENUPARENTID: (state, id) => {
            state.menuParentId = id
        },
        // SET_NAVBTN: (state, btnlist) => {
        //     state.navbarRightButton = btnlist
        // },
        SET_NAVBTNDEFAULT: (state, { key, iconName }) => {
            state.rightBtnDefault[key] = iconName;
        }
    },

    actions: {
        setMenuList({ commit }, meun) {
            commit('SET_MENULIST', meun)
        },
        setMeunTitle({ commit }, title) {
            commit('SET_MEUNTITLE', title)
        },
        setMenuParentId({ commit }, id) {
            commit('SET_MENUPARENTID', id)
        },
        setMeunInfo({ commit }, {}) {
            commit('SET_MENULIST', [])
            commit('SET_MEUNTITLE', "")
            commit('SET_MENUPARENTID', "")
        },
        // setNavBtnIcon({ commit }, { key, iconName }) {
        //     // commit('SET_NAVBTNDEFAULT', { key, iconName })
        // },
    }
}

export default meun
