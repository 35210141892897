import axios from 'axios'
// import { Notification, MessageBox, Message } from 'element-ui'
import { Toast, Dialog } from 'vant';
import store from '@/store'
import { getToken, removeToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import router from '../router'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
        // axios中请求配置有baseURL选项，表示请求URL公共部分
        baseURL: process.env.VUE_APP_BASE_API,
        // 超时
        timeout:  600000
    })
    // request拦截器
service.interceptors.request.use(config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        // 获取错误信息


        const msg = errorCode[code] || res.data.msg||res.data.message || errorCode['default']
        if (code === 401 || code == 10001) {
            console.log(res, code);
            let _timer = setTimeout(() => {
                store.dispatch("FedLogOut").then((res) => {
                    router.replace({ path: "/login" });
                })
                clearTimeout(_timer);
            }, 2000)
        } else if (code === 500) {
            // let _timer = setTimeout(() => {
            //     removeToken();
            //     router.push({
            //         path: "/login",
            //         replace: true
            //     });
            //     clearTimeout(_timer);
            // }, 500)
            Toast.fail(msg)
            return Promise.reject(new Error(msg))
        } else if (code !== 200) {
            Toast.fail(msg)
                // Notification.error({
                //     title: msg
                // })
            return Promise.reject('error')
        } else {
            if (res.status == 404) {
                Toast.fail(msg)
                return Promise.reject('error')
                return;
            } else {
                return res.data
            }
        }
    },
    error => {
        console.log('err' + error)
        let { message, } = error;
        let _code = message.substr(message.length - 3);
        if (_code == "401" || _code == 10001) {
            let _timer = setTimeout(() => {
                store.dispatch("FedLogOut").then((res) => {
                    router.replace({ path: "/login" });
                })
                clearTimeout(_timer);
            }, 500)

        }
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        Toast.fail(message)
            // Message({
            //     message: message,
            //     type: 'error',
            //     duration: 5 * 1000
            // })
        return Promise.reject(error)
    }
)

export default service