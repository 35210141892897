import { isSystem } from "./index"
import { deepClone, stringToObj } from "@/utils/index.js";
import { getToken } from '@/utils/auth'

// 转Base64编码 后
let interaction = {
    /**
     * msgType： 
     *      - openNewWindow 打开新窗口；
     *      - titleVisible 设置标题是否可见
     *      - clearCache 清空webview文件缓存
     *      - setPushAlias 友盟推送添加或移除别名
     *      - closeWindow 关闭当前页面
     *      - openCamera 打开相机拍照并返回图片文件流的Base64字符串
     *      - openAlbum 打开相册
     *            "data:"{"maxCount":1} maxCount指选取图片的最大数量 默认只选取一张图片
     *      - openScanner 打开相机并扫描二维码
     */
    // 成功回到函数
    // successCallback: (res) => {},
    // 失败回到函数
    // errorCallback: (res) => {},
    eventFun: [],
    // eventFun:{
    //     success:[],
    //     error:[],
    // },
    bridge(obj) {
        if (!obj.msgType) {
            return;
        }
        let _obj = deepClone(current._obj);
        _obj.msgType = obj.msgType;
        let _ind = interaction.eventFun.length;
        if (obj.data) {
            _obj.data = obj.data
        }
        // 相册和相机方法需要给原生上送token
        if (obj.msgType == "openCamera" || obj.msgType == "openAlbum") {
            _obj.data = _obj.data || {};
            _obj.data.headers = {
                Authorization: "Bearer " + getToken()
            }
        }
        if (isSystem() == 'ios') {
            _obj.successFun = "successCallback"
            _obj.errorFun = "errorCallback"
            _obj.eventInd = _ind;
        }


        return current._bridge(_obj);

    },
}

const current = {
    iosType: ["statusBarStyle", "setCookie"],
    _obj: {
        "msgType": "",
        // "successFun": "successCallback",
        // "errorFun": "errorCallback",
        // data: {}
    },
    _bridge(obj) {
        return new Promise((reslove, reject) => {
            let _base64Str = window.btoa(JSON.stringify(obj));
            let _str = "UpiMg###" + _base64Str;
            // console.log(isIOS());
            // let _dataS = ""
            if (isSystem() == 'ios') {
                window.webkit.messageHandlers.interaction.postMessage({
                    body: _str
                });
                /**
                 * 把成功和失败函数赋值到 eventFun 中 通过索引定位
                 * 
                 */
                let funInd = obj.eventInd;
                interaction.eventFun[funInd] = {
                    success: reslove,
                    error: reject
                }

            } else {
                // console.log(current.iosType, "current.iosType");
                if (current.iosType.includes(obj.msgType)) {
                    reslove()
                } else {
                    let _dataS = window.prompt("", _str);
                    let _dataJson = JSON.parse(_dataS)
                    if (_dataJson.code) {
                        reject(_dataJson.data)
                    } else {
                        // let _d = eval("(" + _dataJson.data + ")")
                        if (obj.msgType == "openScanner") {
                            let _d = stringToObj(_dataJson.data)
                            reslove(_d)
                        } else {
                            reslove(_dataJson.data)
                        }
                    }
                }
            }
        })
    },

}


window.interaction = interaction;
window.successCallback = function(data) {
    console.log("交互成功回调");
    // console.log(typeof data, data, interaction.eventFun.length);
    let _dataJson = JSON.parse(data)
        // // let _dataJson = data
    if (_dataJson.code) {
        interaction.eventFun[_dataJson.eventInd].success(_dataJson.data)
    } else {
        interaction.eventFun[_dataJson.eventInd].error(_dataJson.data)
    }
}
window.errorCallback = function(data) {

    // interaction.eventFun[data.eventInd].error(data)
}



// const fun = (msg, defaultValue) => {
//         let _s = defaultValue.split("UpiMg###")[1],
//             _obj = JsON.parse(window.atob(_s));
//         console.log(_obj.successFun);
//         let nameArr = _obj.successFun.split(".");
//         let funObj = window;
//         nameArr.forEach((name, i) > {
//             funObj = funObj[name]
//             if (i == nameArr.length - 1) funobj();
//         });
//     })
//         window.prompt fun