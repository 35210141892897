import { login, getInfo, logout, getUserProfile } from '@/api/login'
import { getMessageConfList } from "@/api/message";
import { getToken, setToken, removeToken } from '@/utils/auth'
// import Cookies from 'js-cookie'
import store from '../index'

// const state = getDefaultState();
const clearData = () => {
    let _username = localStorage.getItem("username"),
        _password = localStorage.getItem("password");
    const versionStorage = localStorage.getItem('version')
    localStorage.clear();
    if (_username) {
        localStorage.setItem("username", _username)
        localStorage.setItem("password", _password)
    }
    // 重置版本号
    if(versionStorage){
        localStorage.setItem("version", versionStorage)
    }

    sessionStorage.clear();
    // localStorage.clear();
    removeToken()
}
const user = {
    state: {
        token: getToken(),
        systemList: ["srm-token", "storage-token", "crm-token","mes-token","qps-token", "ehs-token","cloud-token"], //整个项目总共几个系统
        name: '', //名称
        avatar: '',
        roles: [], // 角色
        permissions: [], //权限
        userInfo: {},
        messageModuleList: [], // 消息类型
        upiServerToken: {}, // 中台系统登录几个系统
        srmUserInfo: {}, //srm系统角色信息
        platformId: {} //各系统人员id
        // includelist: [],
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, name) => {
            state.name = name
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions
        },
        SET_INCLUDE: (state, permissions) => {
            state.in = permissions
        },
        SET_USERINFO: (state, userInfo) => {
            state.userInfo = userInfo
        },
        SET_UPISERVERTOKEN: (state, upiServerToken) => {
            state.upiServerToken = upiServerToken
        },
        SET_MESLIST: (state, list) => {
            state.messageModuleList = list
        },
        SET_SRMUSERINFO: (state, srmUserInfo) => {
            state.srmUserInfo = srmUserInfo
        },
        SET_PLATFORMID: (state, platformId) => {
            state.platformId = platformId
        },
        SET_USER_INFO: (state, { }) => {
            state.token = "";
            state.name = "";
            state.avatar = "";
            state.roles = [];
            state.permissions = [];
            state.userInfo = {};
            state.messageModuleList = [];
            state.srmUserInfo = [];
            state.platformId = {};
        },


    },

    actions: {
        // 登录
        Login({ commit, dispatch }, userInfo) {
            //    trim()
            return new Promise((resolve, reject) => {
                login(userInfo).then(res => {
                    setToken(res.token)

                    // dispatch("getUser", {})
                    // commit('SET_TOKEN', res.token)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // getUser({ state }) {
        //     new Promise((resolve, reject) => {
        //         getUser().then(res => {
        //             console.log(state, "缓存");
        //             console.log(res);
        //             // setToken(res.token)
        //             // commit('SET_TOKEN', res.token)
        //             resolve()
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // },
        // 设置头像
        setAvatar({ commit, state }) {
            commit('SET_AVATAR', avatar)
        },
        // 获取用户信息
        GetInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                getInfo(state.token).then(res => {
                    const user = res.user;
                    const avatar = user.avatar == "" ? require("@/assets/imgs/logo.png") : process.env.VUE_APP_BASE_API + user.avatar;
                    if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
                        commit('SET_ROLES', res.roles)
                        commit('SET_PERMISSIONS', res.permissions)
                    } else {
                        commit('SET_ROLES', ['ROLE_DEFAULT'])
                    }
                    commit('SET_NAME', user.userName)
                    commit('SET_AVATAR', avatar)
                    commit('SET_USERINFO', user)
                    commit('SET_UPISERVERTOKEN', res.upiServerToken)
                    commit('SET_PLATFORMID', res.platformId)
                    // srm系统登录人获取信息
                    if (res.upiServerToken && res.upiServerToken[state.systemList[0]]) {
                        getUserProfile().then((response) => {
                            commit('SET_SRMUSERINFO', response.data)
                        });
                    }
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 获取用户消息类型
        GetMessageModuleList({ commit, state }) {
            getMessageConfList().then(res => {
                // console.log("????????", res.rows);
                if (!res.rows) {
                    return
                }
                commit('SET_MESLIST', res.rows)
            })
        },
        // 退出系统
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                logout(state.token).then(() => {
                    commit('SET_USER_INFO', '');
                    // 清空路由缓存
                    store.dispatch("infoRoutes", "");
                    // 清空菜单缓存
                    store.dispatch("setMeunInfo", []);
                    // 清空缓存菜单名称缓存
                    store.dispatch("removeInclude", "");

                    clearData();
                    resolve();

                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 前端 登出
        FedLogOut({ dispatch, commit, state }) {
            return new Promise(resolve => {
                commit('SET_USER_INFO', '');
                // 清空路由缓存
                store.dispatch("infoRoutes", "");
                // 清空菜单缓存
                store.dispatch("setMeunInfo", []);
                // 清空缓存菜单名称缓存
                store.dispatch("removeInclude", "");

                clearData();
                resolve()

            })
        },


    }
}

export default user
