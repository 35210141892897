import router from '@/router'
import { isSystem } from "@/utils/index";
// import Vue from 'vue'
const noAnswerType = ["openNewWindow", "titleVisible", "setPushAlias", "clearCache", "closeWindow", "statusBarStyle", ]
let data = {
    code: 0,
    data: { version: "1.2", system: "window" }
}
const business = {
    /* 跳转页面 */
    openNewWindow: (data) => {
        // router.push
        // console.log(data);
        // let _u = data.url.split("://")[1].split("/");
        // // console.log(_u);
        // delete _u[0];

        // let _url = _u.join("")
        //     // console.log(_u);
        // console.log(_url);
        window.open(data.url, "_self")
            // router.push({
            //     path: _url,
            // });
    },
    /* 设置标题可见 */
    titleVisible: (data) => {

    },
    /* 清空webview文件缓存 */
    clearCache: (data) => {

    },
    /* 友盟推送移除别名 */
    setPushAlias: (data) => {

    },
    /* 关闭当前页面 */
    closeWindow: (data) => {

    },
    /* 拍照 */
    openCamera: (data) => {

    },
    /* 相册 */
    openAlbum: (data) => {

    },
    /* 扫描二维码 */
    openScanner: (data) => {

    },
    /* 修改状态栏样式 */
    statusBarStyle: (data) => {

    },
    /*  */

};

const fun = (msg, defaultValue) => {
    let _s = defaultValue.split("UpiMg###")[1],
        _obj = JSON.parse(window.atob(_s));
    // let nameArr = _obj.successFun;
    console.log(_obj, noAnswerType.includes(_obj.msgType));

    if (noAnswerType.includes(_obj.msgType)) {
        business[_obj.msgType](_obj.data);
    }
    return JSON.stringify(data);
}

if (isSystem() == 'window') {
    window.prompt = fun;
}