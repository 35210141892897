<template>
  <div id="app">
    
    <!-- <router-view/> -->
    <router-view />
  </div>
</template>
<script>
import { isSystem } from "@/utils/index";
export default {
  name: "App",
  //定义metaInfo
  // metaInfo() {
  //   return {
  //     title: "appall",
  //     meta: [
  //       {
  //         name: "apple-mobile-web-app-capable",
  //         content:"content='yes'"
  //       },
  //       {
  //         name: "viewport",
  //         content:
  //           "width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, viewport-fit=cover,user-scalable=no",
  //       },
  //     ],
  //   };
  // },
  data() {
    return {};
  },
  created() {
    // plus.navigator.setStatusBarBackground('#6495ED');
    // 在页面加载时读取sessionStorage里的状态信息
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(localStorage.getItem("store"))
        )
      );
    }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // beforeunload事件在页面刷新时先触发
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state));
    });
    const _system = isSystem();
    // this.$meta().refresh()
    // if (_system != "window") {
    //   let visibilityChange = "";
    //   if (typeof document.hidden !== "undefined") {
    //     // Opera 12.10 and Firefox 18 and later support
    //     visibilityChange = "visibilitychange";
    //   } else if (typeof document.msHidden !== "undefined") {
    //     visibilityChange = "msvisibilitychange";
    //   } else if (typeof document.webkitHidden !== "undefined") {
    //     visibilityChange = "webkitvisibilitychange";
    //   }
    //   document.addEventListener(visibilityChange, () => {
    //     if(!(document.hidden)){
    //       this.$router.go(-1)
    //     }
    //     // this.$store.commit(
    //     //   "SET_DOCUMENT_VISIBILITY",
    //     //   document.hidden ? false : true
    //     // );
    //     // if (document.hidden) {
    //     // } // 页面被挂起
    //     // else {
    //     //   console.log(
    //     //     visibilityChange,
    //     //     document.webkitHidden,
    //     //     "document.webkitHidden"
    //     //   );
    //     //   console.log(document.hidden, "document.hidden");

    //     //   // router.go(-1)
    //     //   // 页面呼出
    //     //   // 增加路由判断 仅当前页面生效
    //     //   // if(window.location.pathname === "/activityMidPage"){
    //     //   //  // ...
    //     //   // }
    //     // }
    //   });
    // }
  },

  // activated: function () {
  //   plus.navigator.setStatusBarBackground('#6495ED');
  //   // plus.navigator.setStatusBarStyle('light');
  // },
  // deactivated: function () {
  //   plus.navigator.setStatusBarBackground('#6495ED');
  //   // plus.navigator.setStatusBarStyle('light');
  // },
  mounted() {},
  methods: {},
};
</script>
<style>
@import "assets/style/index.scss";

#app {
  background: #fff;
}
</style>

