<template>
  <section>
    <!-- <transition name="fade-transform" mode="out-in"> -->
    <div class="app-main" :class="[(tabbarShow ? ' appHomePage ' : ''),(back?' gray ':'')]">
      <keep-alive :include="includeList">
        <router-view />
      </keep-alive>
      <!-- //不需要缓存时 -->
      <!-- <router-view v-if="!$route.meta.noCache"></router-view> -->
    </div>
    <!-- </transition> -->
  </section>
</template>

<script>
import tabbar from "@/utils/tabbar";
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.path;
    },
    includeList() {
      return this.$store.getters.include;
    },
    tabbarShow: function () {
      // console.log(this.$route.name,tabbar);
      let _bool = tabbar.includes(this.$route.name);
      return _bool;
    },
    back: function () {
      return this.$route.name =='personal';
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variable.scss";
.app-main {
  // overflow-x: auto;
  background: #fff;
  min-height: $childPageHeight;
  // margin-top: 50px;
  // overflow: scroll;
}
.appHomePage {
  // height: $childPageHeight;
  // height: $homePageHeight;
  min-height: $mainPageHeight;
  // padding-bottom: calc(72px + constant(safe-area-inset-bottom));
  // padding-bottom: calc(72px + env(safe-area-inset-bottom));
  padding-bottom: 72px;
    // height: calc(72px + env(safe-area-inset-bottom)); /* 直接扩展高度 */
  // padding-bottom: constant(safe-area-inset-bottom); /*兼容 iOS<11.2 */
  // padding-bottom: env(safe-area-inset-bottom); /* 兼容iOS>= 11.2*/
}
.gray {
  background: #f5f5f5;
}
</style>
