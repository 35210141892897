<template>
  <div class="navbarBox">
    <!-- <div class="statusBar"></div> -->
    <div class="navbar">
      <van-nav-bar :left-text="leftBtn ? '返回' : ''" :left-arrow="leftBtn" @click-left="clickLeft" class="title"
        safe-area-inset-top fixed>
        <!-- safe-area-inset-top -->
        <template>
          <slot slot="title" name="title">{{ title }}</slot>
        </template>
        <!-- <template #right v-if="icon">
          <van-icon :name="icon" size="18" />
          <span :name="Tips" style="color:#1989fa">{{Tips}}</span>
        </template> -->
        <template #right v-if="rightBtn" class="rightBtnBox">
          <div style="padding: 10px;" @click="rightBtn.onclick" v-if="rightBtn.special">
            {{ rightBtn.name }}
          </div>
          <!-- 按钮类型 1-图片 2-文字 3-图文 -->
          <div v-else style="display: flex;">
            <div v-for="(iconData, ind) in rightBtn" :key="ind" class="rightIconBox" @click.stop="clickRight(iconData)"
              v-show="(!rigBtnToggle ||
                (rigBtnToggle && index == -1 && iconData.icon != currentIcon) ||
                ind != index) && (iconData.hidden == undefined || !iconData.hidden)
                ">
              <!-- {{ index }} -->
              <van-icon v-if="iconData.buttonType != 2" :name="iconData.icon" size="22"
                :color="iconData.iconColor ? iconData.iconColor : '#fff'" />
              <!-- style="color: #1989fa" -->
              <span v-if="iconData.buttonType != 1" :name="iconData.Tips" style="margin-left: 5px">{{ iconData.Tips
                }}</span>
            </div>
          </div>
        </template>
      </van-nav-bar>
    </div>
  </div>
</template>

<script>
import tabbar from "@/utils/tabbar";
import axios from 'axios';

// const version = require('element-ui/package.json').version
const defaultSettings = require('@/settings.js')
// import { isSystem } from "@/utils/index";
export default {
  name: "Navbar",
  props: {
    // 是否有右侧按钮 按钮的icon名称
    icon: {
      type: String,
      default: () => {
        return "";
      },
    },
    Tips: {
      type: String,
      default: () => {
        return "";
      },
    },
    // 必须传入icon 才可执行； 点击icon执行的函数
    iconEvent: {
      type: String,
      default: () => {
        return "";
      },
    },
    // 页面标题
    pageTitle: {
      type: String,
      default: "",
    },
    // 页面标题
    leftClickEvent: {
      type: Function,
      default: null,
    },
  },
  computed: {
    leftBtn: function () {
      let _bool = tabbar.includes(this.$route.name);
      return !_bool;
    },
    rightBtn: function () {
      let obj = {};

      const rigCont = this.$route.meta.rightIcon;
      // 工厂RFID右上角添加扫一扫功能
      if (this.$route.meta && this.$store.state.meun.meunTitle == "车间线边仓" && this.$route.name == "menuChildPage") {
        obj = { name: '扫一扫', onclick: this.scan, special: true };
      }
      return rigCont || obj;
    },
    title() {
      return this.pageTitle ? this.pageTitle : this.$route.meta.title;
    },
    rigBtnToggle() {
      const _routeName = this.$route.name,
        _rightBtnDefault = this.$store.getters.rightBtnDefault;
      let _bool = false;

      if (_rightBtnDefault[_routeName]) {
        _bool = true;
      }
      return _bool;
    },
    currentIcon() {
      const _routeName = this.$route.name,
        _rightBtnDefault = this.$store.getters.rightBtnDefault;
      let iconName = "";
      /**
       * 判断 如果在store中设置了 有切换字段 更加当前路由name
       * 更行index显示
       * 在data中设置当前navbar 右侧按钮是否需要切换隐藏
       */
      if (_rightBtnDefault[_routeName]) {
        iconName = _rightBtnDefault[_routeName];
      }
      return iconName;
    },
  },
  data() {
    return {
      index: -1,
    };
  },
  watch: {
    $route() {
      let _ind = -1;
      if (this.rigBtnToggle) {
        const _showData = this.rightBtn.map((item) => {
          return item.icon;
        });

        _ind = _showData.indexOf(this.currentIcon);
      }
      this.index = _ind;
      this.checkVersion();
    },
  },

  created() { },
  mounted() {
    if (this.rigBtnToggle) {
      const rigCont = this.rightBtn,
        _rightBtnDefault = this.$store.getters.rightBtnDefault,
        _routeName = this.$route.name;
      const _showData = rigCont.map((item) => {
        return item.icon;
      });

      const _currentInd = _showData.indexOf(this.currentIcon);
      this.index = _currentInd;
    }
    axios.defaults.headers["Cache-Control"] = 'no-cache';
    this.checkVersion();
  },
  methods: {
    scan() {
      interaction.bridge({ msgType: "openScanner", errorFun: "callbackFailed" }).then(res => {
        const Arr = Object.keys(res);
        this.$router.push(`/ibcDetail?epcCode=${Arr[0]}`);
      })
    },
    // 返回
    clickLeft() {
      // 获取当前页面的 URL
      const currentUrl = window.location.pathname;
      // 判断当前页面的 URL 是否包含特定的字符串
      if (currentUrl.includes('InspectionRoute')) {
        this.$router.push({ path: '/Inspectiontasks' });
        return;
      } else if (currentUrl.includes('Inspectiontasks')) {
        this.$router.push({ path: 'menu' });
        return;
      } else if (currentUrl.includes('realInventory')) {
        const goBack = localStorage.getItem('goBack');
        console.log('要返回了', goBack);
        if (goBack == 'true') {
          this.$toast.fail('请提交本次盘点操作后再返回')
          return
        } else {
          this.goBack();
          return
        }
        return
      } else {
        this.goBack();

      }
      // 在这里可以执行其他逻辑
      // this.goBack();
      // if (isSystem() == "window") {
      //   this.$router.go(-1);
      // } else {
      //   interaction.bridge({
      //     msgType: "closeWindow",
      //   });
      // }
    },
    // 右侧按钮
    clickRight(iconData) {
      if (this.rigBtnToggle) {
        this.index =
          this.index + 1 >= this.rightBtn.length ? 0 : this.index + 1;
        this.$store.commit("SET_NAVBTNDEFAULT", {
          key: this.$route.name,
          iconName: this.rightBtn[this.index].icon,
        });
      }

      if (iconData.iconEvent) {
        this.$parent.tabbarIconEvent(
          iconData.iconEvent,
          this.index > -1 ? this.rightBtn[this.index].icon : null
        );
      }
      // if (iconData.iconEvent) {
      //   this.$parent.tabbarIconEvent(
      //     iconData.iconEvent,
      //     this.rightBtn[this.index].icon
      //   );
      // }
    },
    checkVersion() {
      axios.get(`https://${window.location.host}/version.json`).then(({ data }) => {
        if (data.version != defaultSettings.version) {
          let _obj = {
            msgType: "clearCache",
            data: { clearType: "data" },
          };
          interaction
            .bridge(_obj)
            .then((res) => {
              window.location.reload(true);
            })
        }
        // if(data.version)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.navbarBox {
  // height: 50px;
  // height: 100px;
  // // background: #26bfb2;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  position: relative;
}

// .statusBar{
//   width: 100%;
//   position: absolute;
//   bottom: 50px;
//   left: 0;
//   height: 100px;
//   background: #707070;
// }
.navbar {
  height: 50px;
  width: 100%;
  // background: #fff;
  // text-align: center;
  // border-bottom: 1px solid #dcdfe6;
  // box-sizing: border-box;
  // line-height: 50px;
  border: none;
  // padding-top: constant(safe-area-inset-top);
  // padding-top: env(safe-area-inset-top);
  // background: #ccc;
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 999;
}

.van-nav-bar {
  background: #26bfb2;
  color: #fff;
  height: 50px;
  z-index: 9999;

  /deep/.van-nav-bar__title,
  /deep/.van-icon,
  /deep/.van-nav-bar__text {
    color: #fff;
  }
}

/deep/.van-nav-bar__right {
  padding-right: 0px;
}

.rightIconBox {
  display: flex;
  justify-content: center;
  padding: 10px;
  // margin: -10px;
  // &:last-child {
  //   margin-right: -5px;
  // }
}
</style>
