import request from '@/utils/request'

// 登录
export function login(query) {
    return request({
        url: '/login',
        method: 'POST',
        data: query
    })
}

// 获取验证码
export function captchaImage() {
    return request({
        url: 'captchaImage',
        method: 'get',
        // params: query
    })
}

// 获取移动端路由信息
export function getAppRouters() {
    return request({
        url: 'getAppRouters',
        method: 'get',
        // params: query
    })
}

// 根据用户编号获取详细信息
export function getInfo() {
    return request({
        url: '/getInfo',
        method: 'get',
        // params: query
    })
}
// 退出方法
export function logout() {
    return request({
        url: '/logout',
        method: 'post'
    })
}

// 更新登录设备
export function refreshDeviceToken(data) {
    return request({
        url: '/system/user/refreshDeviceToken',
        method: 'post',
        data
    })
}

//获取srm 用户信息
export function getUserProfile() {
    return request({
        url: process.env.VUE_APP_GONGCHANG_URL+ '/system/user/profile',
        method: 'get'
    })
}

// 新增用户
export function addUser(data) {
  return request({
      url: process.env.VUE_APP_CRM_URL+  '/system/user/register',
      method: 'post',
      data: data
  })
}